import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcTransport } from '@protobuf-ts/runtime-rpc';
import { AuthenticationClient } from '@sparx/api/apis/sparx/assessment/app/v1/authentication.client';
import { SittingsClient } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting.client';
import { AssessmentServiceClient } from '@sparx/api/apis/sparx/assessment/v1/assessment.client';
import {
  CurriculumSummariesClient,
  TopicSummariesClient,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';
import { ActivitiesClient } from '@sparx/api/apis/sparx/packageactivity/v1/activity.client';
import { PackagesClient } from '@sparx/api/apis/sparx/packageactivity/v1/package.client';
import { ReaderReportGenClient } from '@sparx/api/apis/sparx/reports/reportgen/v1/reader.client';
import { SchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { SchoolActionsServiceClient } from '@sparx/api/apis/sparx/school/v2/schoolactions.client';
import { SchoolsServiceClient } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { GroupsAPIClient } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi.client';
import { SchoolStatusServiceClient } from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.client';
import { StudentAPIClient } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi.client';
import { AccessTokenProvider, authInterceptor, extensionInterceptor } from '@sparx/grpcweb';
import { getToken, redirectToLogin } from 'api/auth';

const tokenFetcher = () =>
  getToken().then(async resp => {
    if (!resp.ok && resp.status === 401) {
      // Unauthorised - redirect to login page
      await redirectToLogin();
    }
    return resp;
  });

// Configure the access token provider and set it to load periodically
export const accessTokenProvider: AccessTokenProvider = new AccessTokenProvider(tokenFetcher);
export const startAccessTokenProvider = async (initialToken?: string) => {
  if (!accessTokenProvider.isStarted()) {
    await accessTokenProvider.start(initialToken);
  }
};

interface TransportOptions {
  keepalive?: boolean;
  noAuthInterceptor?: boolean;
}

export const getTransport = (url: string, options?: TransportOptions): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    fetchInit: {
      credentials: 'include',
      keepalive: options?.keepalive || false, // Try turning off (TODO: reconsider whether this had an impact)
    },
    interceptors: [
      ...(!options?.noAuthInterceptor ? [authInterceptor(accessTokenProvider)] : []),
      extensionInterceptor,
    ],
  });

export const authenticationClient = new AuthenticationClient(
  getTransport(window.settings?.assessmentsApiUrl || '', {
    noAuthInterceptor: true,
  }),
);
export const authenticationClientWithAuth = new AuthenticationClient(
  getTransport(window.settings?.assessmentsApiUrl || ''),
);
export const sittingsClient = new SittingsClient(
  getTransport(window.settings?.assessmentsApiUrl || ''),
);
export const assessmentsClient = new AssessmentServiceClient(
  getTransport(window.settings?.teacherApiUrl || ''),
);

export const packagesClient = new PackagesClient(
  getTransport(window.settings?.assessmentsApiUrl || ''),
);
export const activitiesClient = new ActivitiesClient(
  getTransport(window.settings?.assessmentsApiUrl || ''),
);

export const studentClient = new StudentAPIClient(
  getTransport(window.settings?.teacherApiUrl || ''),
);
export const groupsClient = new GroupsAPIClient(getTransport(window.settings?.teacherApiUrl || ''));

export const topicsClient = new TopicSummariesClient(
  getTransport(window.settings?.contentSummariesApiUrl || ''),
);
export const curriculumsClient = new CurriculumSummariesClient(
  getTransport(window.settings?.contentSummariesApiUrl || ''),
);
export const schoolStaffClient = new SchoolStaffServiceClient(
  getTransport(window.settings?.teacherApiUrl || ''),
);
export const schoolsClient = new SchoolsServiceClient(
  getTransport(window.settings?.schoolStoreApi || ''),
);
export const schoolStatusClient = new SchoolStatusServiceClient(
  getTransport(window.settings?.schoolStatusApi || ''),
);
export const schoolActionsClient = new SchoolActionsServiceClient(
  getTransport(window.settings?.schoolActionsApi || '')
);
export const readerReportGenClient = new ReaderReportGenClient(
  getTransport(window.settings?.readerApi || ''),
);
