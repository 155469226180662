import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useGroups, useYearGroups } from 'api/groups';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { useNavigationControlContext, useSelectedGroup } from 'components/header/BackLink';
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel } from 'components/menu/Menu';
import { AnimatePresence, motion } from 'motion/react';
import { useMemo } from 'react';

import styles from './ClassSelect.module.css';

const groupTypeToSubject: Partial<Record<StudentGroupType, string>> = {
  [StudentGroupType.CLASS]: 'Maths',
  [StudentGroupType.CLASS_ENGLISH]: 'English',
  [StudentGroupType.CLASS_SCIENCE]: 'Science',
};

export const ClassSelect = () => {
  const { classSelectEnabled, studentGroupFilter, selectedGroupName, setSelectedGroupName } =
    useNavigationControlContext();

  const { data: groups, isLoading: isLoadingGroups } = useGroups({
    suspense: true,
    enabled: classSelectEnabled,
  });
  const { data: yearGroups, isLoading: isLoadingYearGroups } = useYearGroups({
    suspense: true,
    enabled: classSelectEnabled,
    select: data =>
      data.yearGroups.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
  });

  const isLoading = isLoadingGroups || isLoadingYearGroups;

  const selectedGroup = useSelectedGroup(selectedGroupName, groups, yearGroups);

  const { filtered, others } = useMemo(() => {
    const [filtered, others]: Group[][] = [[], []];
    for (const group of groups?.sort((a, b) =>
      (a.displayName || '').localeCompare(b.displayName || '', undefined, { numeric: true }),
    ) || []) {
      if (group.type === studentGroupFilter) {
        filtered.push(group);
      } else {
        others.push(group);
      }
    }
    return { filtered, others };
  }, [groups, studentGroupFilter]);

  const dropdown = (
    <motion.div
      initial={{ opacity: 0, marginLeft: -10 }}
      animate={{ opacity: 1, marginLeft: 0 }}
      exit={{ opacity: 0, marginLeft: -10 }}
      transition={{ type: 'spring', bounce: 0 }}
      style={{ position: 'relative', zIndex: 11 }}
    >
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <Button isLoading={isLoading} rightIcon={<FontAwesomeIcon icon={faCaretDown} />}>
            {!selectedGroup
              ? 'Select class'
              : selectedGroup?.type === 'yeargroup'
                ? selectedGroup.yearGroup.name
                : selectedGroup.group.displayName}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenuContent rounded="all" align="start" className={styles.Menu}>
          {studentGroupFilter && (
            <div className={styles.MenuGroup}>
              <DropdownMenuLabel className={styles.GropLabel}>
                {groupTypeToSubject[studentGroupFilter]} classes:
              </DropdownMenuLabel>
              {filtered.map(g => (
                <DropdownMenuItem
                  key={g.name}
                  onClick={() => setSelectedGroupName(g.name)}
                  className={classNames(styles.Group, {
                    [styles.SelectedGroup]: selectedGroupName === g.name,
                  })}
                >
                  {g.displayName}
                </DropdownMenuItem>
              ))}
              {filtered.length === 0 && <span className={styles.NoClasses}>No classes</span>}
            </div>
          )}
          <div className={styles.MenuGroup}>
            <DropdownMenuLabel className={styles.GropLabel}>Other classes:</DropdownMenuLabel>
            {others.map(g => (
              <DropdownMenuItem
                key={g.name}
                onClick={() => setSelectedGroupName(g.name)}
                className={classNames(styles.Group, {
                  [styles.SelectedGroup]: selectedGroupName === g.name,
                })}
              >
                {g.displayName}
              </DropdownMenuItem>
            ))}
          </div>
          <div className={styles.MenuGroup}>
            <DropdownMenuLabel className={styles.GropLabel}>Year groups:</DropdownMenuLabel>
            {yearGroups?.map(g => (
              <DropdownMenuItem
                key={g.name}
                onClick={() => setSelectedGroupName(`yeargroup/${g.yearGroupID}`)}
                className={classNames(styles.Group, {
                  [styles.SelectedGroup]: selectedGroupName === `yeargroup/${g.yearGroupID}`,
                })}
              >
                {g.name}
              </DropdownMenuItem>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </motion.div>
  );

  return <AnimatePresence>{classSelectEnabled && dropdown}</AnimatePresence>;
};
