import {
  faCheck,
  faChevronRight,
  faCircleCheck,
  faClipboard,
  faTimes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CreateSittingRequest,
  CreateSittingResponse,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useAssessment } from '@sparx/assessments/src/api/hooks';
import { FormControl } from '@sparx/sparx-design/components/form/FormControl';
import { FormLabel } from '@sparx/sparx-design/components/form/FormLabel';
import { Input } from '@sparx/sparx-design/components/input/Input';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useSession } from 'api/auth';
import { CreateSittingParams, useCreateSitting } from 'api/sittings';
import { AssessmentCard } from 'components/assessmentselector/AssessmentCard';
import { AssessmentSelector } from 'components/assessmentselector/AssessmentSelector';
import { Button } from 'components/button/Button';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';

import styles from './CreateSittingView.module.css';

export const CreateSittingView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAssessment = searchParams.get('assessment') || '';
  const setSelectedAssessment = (assessmentName: string | null) => {
    setSearchParams(assessmentName ? { assessment: assessmentName } : {});
  };

  const [result, setResult] = useState<CreateSittingResponse | undefined>();
  const { data: assessment } = useAssessment(
    selectedAssessment || '',
    {
      subjectName: 'subjects/-',
      includeAssessmentGroupAssessments: false,
    },
    { suspense: true },
  );

  return (
    <PageContainer>
      {result ? (
        <ResultPage result={result} />
      ) : !assessment ? (
        <>
          <PageHeader back="/teacher/sittings">Create Sitting</PageHeader>
          <SelectAssessment onSelect={setSelectedAssessment} />
        </>
      ) : (
        <>
          <PageHeader
            back={() => setSelectedAssessment(null)}
            title={`Create sitting - ${assessment.displayName}`}
          >
            <PageHeaderSubpage before={true} to={() => setSelectedAssessment(null)}>
              Create Sitting
            </PageHeaderSubpage>
            {assessment.displayName}
          </PageHeader>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 2fr',
              gap: 'var(--spx-unit-4)',
            }}
          >
            <Stack direction="column" spacing={4}>
              <h4>Selected assessment:</h4>
              <AssessmentCard assessment={assessment} />
            </Stack>
            <div className={styles.Form}>
              <CreateSitting assessment={assessment} onResult={setResult} />
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

const ResultPage = ({ result }: { result: CreateSittingResponse }) => {
  const { data: { schoolId } = {} } = useSession({ suspense: true });
  const ref = useRef<HTMLTableElement>(null);

  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied, setCopied]);

  const copy = () => {
    if (ref.current) {
      console.log(ref.current.outerHTML);
      const blob = new Blob([ref.current.outerHTML], { type: 'text/html' });
      navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
      setCopied(true);
    }
  };

  const rows = result.sittings.map(sitting => {
    const token = result.guestTeacherTokens[sitting.sittingName];
    const url = window.location.origin + '/guest?t=' + token + '&school=' + schoolId;
    return {
      ...sitting,
      groupName:
        result.sittingGroups.find(g => g.sittingGroupName === sitting.sittingGroupName)
          ?.manualName || '<Unknown>',
      guestUrl: token ? url : '-',
    };
  });

  return (
    <>
      <div>
        <Stack spacing={4} justify="center" className={styles.CreatedBanner}>
          <FontAwesomeIcon icon={faCircleCheck} size="3x" color="var(--leaf-green-80)" />
          <h1>Sittings created</h1>
        </Stack>
        <table className={styles.Table}>
          <thead>
            <tr>
              <th />
              <th>Class</th>
              <th>Title</th>
              <th>Join code</th>
              <th>Guest Teacher Link</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(sitting => {
              return (
                <tr key={sitting.sittingName}>
                  <td>
                    <Link
                      target={'_blank'}
                      to={`/teacher/sittings/${sitting.sittingName.split('/')[3]}`}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </Link>
                  </td>
                  <td>{sitting.groupName}</td>
                  <td>{sitting.title}</td>
                  <td>{sitting.joinCode}</td>
                  <td className={styles.TokenField}>
                    <span>{sitting.guestUrl}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles.CopyableTable}>
          <table border={1} cellSpacing={0} cellPadding={5} ref={ref}>
            <thead>
              <tr>
                <th>
                  <strong>Class</strong>
                </th>
                <th>
                  <strong>Title</strong>
                </th>
                <th>
                  <strong>Join code</strong>
                </th>
                <th>
                  <strong>Guest Teacher Link</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map(sitting => {
                return (
                  <tr key={sitting.sittingName}>
                    <td>{sitting.groupName}</td>
                    <td>{sitting.title}</td>
                    <td>{sitting.joinCode}</td>
                    <td>
                      <a href={sitting.guestUrl} target="_blank" rel="noreferrer">
                        {sitting.guestUrl}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Stack spacing={4} justify="center" className={styles.CreatedButton}>
          <Button
            onClick={copy}
            leftIcon={<FontAwesomeIcon icon={copied ? faCheck : faClipboard} fixedWidth={true} />}
            variant="outline"
          >
            Copy table to clipboard
          </Button>
          <Button
            as={Link}
            to="/teacher/sittings"
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Back to sittings
          </Button>
        </Stack>
      </div>
    </>
  );
};

const SelectAssessment = ({ onSelect }: { onSelect: (assessmentName: string) => void }) => {
  return <AssessmentSelector onSelect={onSelect} />;
};

const CreateSitting = ({
  assessment,
  onResult,
}: {
  assessment: Assessment;
  onResult: (result: CreateSittingResponse) => void;
}) => {
  // const navigate = useNavigate();
  const { mutateAsync: createSitting, isLoading } = useCreateSitting({
    onSuccess: onResult,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<CreateSittingParams>({
    defaultValues: CreateSittingRequest.create({
      title: assessment.displayName,
      assessmentName: assessment.name,
      ukYearGroup: 7,
    }),
  });

  const groups = watch('groups');

  const [groupEntry, setGroupEntry] = useState('');
  const addGroupName = () => {
    const name = groupEntry.trim();
    if (groupEntry.length > 0) {
      if (!groups.find(g => g.manualName === name)) {
        setValue('groups', [...groups, { manualName: name }], { shouldTouch: false });
      }
      setGroupEntry('');
    }
  };
  const removeGroupAtIndex = (index: number) => {
    setValue(
      'groups',
      groups.filter((_, i) => i !== index),
      { shouldTouch: false },
    );
  };

  return (
    <form
      onSubmit={handleSubmit(req => {
        if (groups.length === 0) return;
        return createSitting(CreateSittingRequest.create(req));
      })}
    >
      <Stack direction="column" spacing={4}>
        <FormControl isInvalid={Boolean(errors.title)}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            {...register('title', {
              required: true,
            })}
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors.title)}>
          <FormLabel>Year group</FormLabel>
          <Input
            type="number"
            {...register('ukYearGroup', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors.groups)}>
          <FormLabel>Classes</FormLabel>
          <p className={styles.Instruction}>Enter the name of the classes taking the assessment:</p>
          <div className={styles.GroupContainer}>
            <AnimatePresence>
              {groups.map((group, i) => (
                <motion.div
                  key={group.manualName}
                  className={styles.Group}
                  initial={{ width: 0, margin: 0 }}
                  animate={{ width: 'auto', marginRight: 'var(--spx-unit-2)' }}
                  exit={{ width: 0, margin: 0 }}
                >
                  <span>{group.manualName}</span>
                  <div className={styles.RemoveGroup} onClick={() => removeGroupAtIndex(i)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
            <Input
              isInvalid={isSubmitted && groups.length === 0}
              className={styles.GroupInput}
              type="string"
              placeholder="Add a class..."
              value={groupEntry}
              id="group-entry"
              onChange={e => setGroupEntry(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addGroupName();
                }
              }}
            />
            <Button
              isDisabled={groupEntry.trim().length === 0}
              onClick={e => {
                e.preventDefault();
                addGroupName();
                document.getElementById('group-entry')?.focus();
              }}
            >
              Add
            </Button>
          </div>
        </FormControl>

        <div>
          <Button type="submit" isDisabled={groups.length === 0} isLoading={isLoading}>
            Create online sitting{groups.length === 1 ? '' : 's'}
          </Button>
        </div>
      </Stack>
    </form>
  );
};
