import { AdditionalSupport, Hint, HintType } from '@sparx/api/apis/sparx/hints/v1/hints';
import { Button } from '@sparx/sparx-design/components';
import { VideoIcon } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { useAnimate } from 'motion/react';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useSparxQuestionContext } from '../../question/SparxQuestionContext';
import { ReactComponent as HintImageSupport } from './HintImageSupport.svg';
import styles from './Hints.module.css';
import { HintInfo } from './types';

export const HintArrow = ({ partHasInput }: { partHasInput: boolean }) => {
  const [shouldShow, setShouldShow] = useState(!partHasInput);
  // once we have input in the part, we should hide the arrow
  useEffect(() => {
    if (partHasInput) {
      setShouldShow(false);
    }
  }, [partHasInput]);
  return (
    <div
      className={classNames({ [styles.HintArrow]: true, [styles.Hidden]: !shouldShow })}
      role="presentation"
    >
      👉
    </div>
  );
};

export const ResetButton = ({
  hintInfo,
  partHasInput,
}: {
  hintInfo: HintInfo | undefined;
  partHasInput: boolean;
}) => {
  return (
    <>
      {hintInfo && (
        <div
          className={classNames({
            [styles.ResetButtonContainer]: true,
            [styles.Hidden]: !partHasInput,
          })}
          data-reset-button
          role="presentation"
        >
          <Button
            variant="text"
            className={styles.ResetButton}
            onMouseDown={() => hintInfo.onReset()}
          >
            reset
          </Button>
        </div>
      )}
    </>
  );
};

export const HintBannerWrapper = ({ children }: { children: ReactNode }) => {
  const { hintInfo } = useSparxQuestionContext();

  return (
    <>
      <HintBanner hint={hintInfo?.hint} />
      {children}
    </>
  );
};

const HintBanner = ({ hint }: { hint: Hint | undefined }) => {
  const [animationScope, animate] = useAnimate();
  const { numHints } = useSparxQuestionContext();

  useEffect(() => {
    const doAnimate = async () => {
      if (numHints === 1) {
        await animate(animationScope.current, { opacity: [0, 1] }, { duration: 0.4 });
      }
      await animate(
        animationScope.current,
        {
          rotate: [0, 1, -1, 1, 0],
        },
        { duration: 0.3, delay: 0.3 },
      );
    };
    doAnimate();
  }, [numHints, animate, animationScope]);

  const hintContent = useMemo(() => {
    switch (hint?.hintType) {
      case HintType.HINT_SIMPLIFY_FRACTION:
        return (
          <HintBannerContent
            hint={hint}
            text={
              (numHints || 0) > 1
                ? 'Keep going! You can still simplify this answer.'
                : 'Nearly! Fully simplify your answer.'
            }
            additionalSupport={true}
          />
        );
      case HintType.HINT_BEARINGS:
        return (
          <HintBannerContent
            hint={hint}
            text="Nearly! Bearings are written with three digits."
            additionalSupport={true}
          />
        );
      case HintType.HINT_MONEY:
        return (
          <HintBannerContent
            hint={hint}
            text="Nearly! Money is written to 2 decimal places."
            additionalSupport={true}
          />
        );
      case HintType.HINT_ROUNDING:
        return <HintBannerContent hint={hint} text="Nearly! Check your rounding." />;
      case HintType.HINT_TARGETED_SUPPORT:
        return (
          <HintBannerContent
            hint={hint}
            text={hint.targetedSupport?.supportText}
            showReturnToQuestion={hint.targetedSupport?.returnToQuestion}
            additionalSupport={hint.targetedSupport?.additionalSupport}
          />
        );
      default:
        return null;
    }
  }, [hint, numHints]);

  return (
    <div ref={animationScope} className={styles.HintContainer}>
      <div className={styles.Hint}>{hintContent}</div>
    </div>
  );
};

type HintBannerContentProps = {
  hint: Hint;
  text: string | undefined;
  showReturnToQuestion?: boolean;
  additionalSupport?: AdditionalSupport | true;
};

const HintBannerContent = ({
  hint,
  showReturnToQuestion,
  text,
  additionalSupport,
}: HintBannerContentProps) => {
  const { triggerHintModal, returnToQuestion } = useSparxQuestionContext();
  showReturnToQuestion = showReturnToQuestion && !!returnToQuestion;

  if (!text) {
    return null;
  }
  const lastChar = text[text.length - 1];
  if (lastChar !== '.' && lastChar !== '?' && lastChar !== '!') {
    text += '.';
  }

  const hasModal =
    !!triggerHintModal &&
    (additionalSupport === true ||
      !!additionalSupport?.text ||
      !!additionalSupport?.videoId ||
      !!additionalSupport?.imageId);
  const showVideoIcon = () => {
    switch (hint.hintType) {
      case HintType.HINT_SIMPLIFY_FRACTION:
        return true;
      case HintType.HINT_TARGETED_SUPPORT:
        return typeof additionalSupport !== 'boolean' && !!additionalSupport?.videoId;
      default:
        return false;
    }
  };

  return (
    <>
      <TextWithMaths text={text} className={styles.Text} />
      {(showReturnToQuestion || hasModal) && ' '}
      {showReturnToQuestion && (
        <button
          className={classNames(styles.HintModalLink, accessibilityStyles.FocusTarget)}
          onClick={returnToQuestion}
        >
          <span>Go back to question</span>
        </button>
      )}
      {showReturnToQuestion && hasModal && <span> or </span>}
      {hasModal && (
        <button
          className={classNames(styles.HintModalLink, accessibilityStyles.FocusTarget)}
          onClick={() => triggerHintModal(hint)}
        >
          <span>{showReturnToQuestion ? 'see' : 'See'} how</span>{' '}
          {showVideoIcon() ? <VideoIcon /> : <HintImageSupport />}
        </button>
      )}
      {!showReturnToQuestion && <span> Correct your answer below</span>}
    </>
  );
};
