import { Hint } from '@sparx/api/apis/sparx/hints/v1/hints';
import { createContext, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { HintInfo } from '../components/Hints/types';
import { getSeededRand, shuffleArray } from '../utils/shuffle';
import { QuestionAction } from './input';
import { MediaInputSettings, QuestionPasteEvent } from './SparxQuestion';
import { IElement, IInput, newInput } from './types';

export type QuestionMode = 'question' | 'answer' | 'combined';
export type QuestionMarkingMode = 'part' | 'gap' | 'gap-summary';
export type InsightsMode = 'presentation' | 'print';
export type ImageLoadingState = 'loading' | 'loaded' | 'error';

export interface SparxQuestionContext {
  input: IInput;
  sendAction: (action: QuestionAction) => void;
  setOpenElementRef: (action: SetStateAction<string>) => void;
  openElementRef: string;
  setIsWaitingForAnimation: (action: SetStateAction<boolean>) => void;
  isWaitingForAnimation: boolean;
  nextInputRef: () => string | undefined;
  isSingleNumericInput: boolean;
  gapEvaluations?: Record<string, GapEvaluation>;
  readOnly?: boolean;
  insightsMode?: InsightsMode;
  shuffleSeed?: string;
  annotations?: Record<string, string>;
  onPaste?: (e: QuestionPasteEvent) => void;
  mode?: QuestionMode;
  dragInProgress: boolean;
  scale: number;
  setScale?: (action: SetStateAction<number>) => void;
  recalculateScaleTrigger: boolean;
  setRecalculateScaleTrigger?: () => void;
  questionElement: HTMLElement | null;
  getUploadedAsset?: (name: string) => React.ReactNode;
  getAssetUrl?: (value: string) => Promise<string>;
  keyboardMode?: boolean;
  questionMarkingMode?: QuestionMarkingMode;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  sendAnalyticEvent: (action: string, labels?: Record<string, string>) => void;
  imageLoadingCallback?: (src: string, state: ImageLoadingState) => void;
  hintInfo?: HintInfo;
  // a count of the number of hints shown
  numHints?: number;
  // opens the specified hint modal
  triggerHintModal?: (hint: Hint) => void;
  returnToQuestion?: () => void;
  // the currently focussed input (if any)
  focussedInputRef?: string;
  imageContainerClassName?: string;
  mediaInputSettings?: MediaInputSettings;
  trialTextFieldSpeechToText?: boolean;
}

export interface GapEvaluationDetail {
  availableMarks: number;
  awardedMarks: number;
  feedback: string;
  feedbackHint?: string;
  errorMarking?: boolean;
}

export interface GapEvaluation {
  correct: boolean;
  correction?: string;
  detail?: GapEvaluationDetail[];
  additionalData?: Record<string, string>;
}

export const SparxQuestionContextDefaultValues: SparxQuestionContext = {
  sendAction: () => undefined,
  setOpenElementRef: () => undefined,
  openElementRef: '',
  setIsWaitingForAnimation: () => undefined,
  isWaitingForAnimation: false,
  nextInputRef: () => undefined,
  isSingleNumericInput: false,
  input: newInput(),
  readOnly: false,
  insightsMode: undefined,
  annotations: {},
  dragInProgress: false,
  scale: 1,
  recalculateScaleTrigger: false,
  questionElement: null,
  sendAnalyticEvent: () => undefined,
  numHints: 0,
};

export const context = createContext<SparxQuestionContext>(SparxQuestionContextDefaultValues);

export const useSparxQuestionContext = () => useContext(context);

export const SparxQuestionContextProvider = context.Provider;

export interface LayoutElementProps<T = IElement> {
  element: T;
  // Parent is an HTMLElement which this layout element should be fitted to
  parent?: HTMLElement | null;
  answerPartIndex?: number;
}

export const doShuffle = <T>(content: T[], shuffle?: boolean, shuffleSeed?: string) => {
  if (shuffle) {
    const seed = shuffleSeed ? getSeededRand(shuffleSeed) : Math.random;
    return shuffleArray([...content], seed);
  }
  return content;
};

export const usePredictableShuffleContent = <T>(
  content: T[],
  shuffle?: boolean,
  seedModifier = '',
): T[] => {
  const context = useSparxQuestionContext();
  const shuffleSeed = context.shuffleSeed + seedModifier;
  const [shuffledArray, setShuffledArray] = useState<T[]>(() =>
    doShuffle(content, shuffle, shuffleSeed),
  );
  useEffect(
    () => setShuffledArray(doShuffle(content, shuffle, shuffleSeed)),
    [content, shuffle, shuffleSeed, setShuffledArray],
  );
  return shuffledArray;
};

export const useFixedChoiceElementsSort = (content: IElement[], enabled?: boolean): IElement[] =>
  useMemo(() => {
    // If not enabled, then return content as is
    if (!enabled) return content;

    // Additional sorting to fix 'idontknow' element to the bottom of the list
    const rest: IElement[] = [];
    const bottom: IElement[] = [];
    for (const element of content) {
      if (getNormalisedElementText(element) === 'idontknow') {
        bottom.push(element);
      } else {
        rest.push(element);
      }
    }
    return [...rest, ...bottom];
  }, [content, enabled]);

// Returns a normalised text value of the element with any non a-z characters removed.
const getNormalisedElementText = (element: IElement) =>
  getElementText(element)
    .toLowerCase()
    .replace(/[^a-z]/g, '');

// Returns a text value of the element.
const getElementText = (element: IElement, val = ''): string => {
  if (element.element === 'text') {
    return val + element.text;
  } else if (element.element === 'group' || element.element === 'choice') {
    return element.content.reduce((acc, el) => getElementText(el, acc), val);
  }
  return val;
};
