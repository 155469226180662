import {
  faCirclePause,
  faCompress,
  faExpand,
  faHourglass,
  faSignOut,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  PauseState,
  Sitting,
  SittingDeliveryState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { logout, redirectToRoot, useSession } from 'api/auth';
import { updateVoidedPackages, usePackage, useStudentSittingPackages } from 'api/packages';
import { useSittingSession, useWatchSitting } from 'api/sittings';
import { useSittingParticipantStatePusher } from 'api/statepusher';
import { updateOffset } from 'api/timeoffset';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { AnimatedFilledPage } from 'components/filledpage/FilledPage';
import { LargeLoadingPage } from 'components/largeloading/LargeLoading';
import { Logo } from 'components/logo/Logo';
import { DropdownMenuContent, DropdownMenuItem, DropdownSeparator } from 'components/menu/Menu';
import { AnimatePresence } from 'motion/react';
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AdaptiveAssessmentPackage } from 'views/student/studentsittingview/adaptive/AdaptiveAssessment';

import { QuizPackage } from './QuizPackage';
import styles from './StudentSittingView.module.css';

enum EndReason {
  REMOVED = 'removed',
  ENDED = 'ended',
  COMPLETED = 'completed',
  OTHER_SESSION = 'other_session',
}

export const StudentSittingView = ({ sittingName }: { sittingName: string }) => {
  // Load a unique session identifier for this sitting
  const { data: session } = useSittingSession(sittingName, { suspense: true });
  const sessionID = session?.sessionId;
  if (!sessionID) {
    throw new Error('Session ID not found');
  }

  const [ended, setEnded] = useState<EndReason | null>(null);
  return (
    <AnimatePresence>
      {ended ? (
        <SittingEndedView reason={ended} />
      ) : (
        <SittingView sittingName={sittingName} sessionID={sessionID} onEnd={setEnded} />
      )}
    </AnimatePresence>
  );
};

interface EndScreenSettings {
  title: string;
  description: string;
  reloadable?: boolean;
  showLogout?: boolean;
}

const sittingEndedMessage: Record<EndReason, EndScreenSettings> = {
  [EndReason.REMOVED]: {
    title: 'You have been removed from this assessment',
    description: 'You have been removed from this assessment by your teacher.',
  },
  [EndReason.ENDED]: {
    title: 'Assessment ended',
    description: 'The assessment has been ended by your teacher.',
  },
  [EndReason.COMPLETED]: {
    title: 'Assessment completed',
    description: 'You have completed the assessment.',
  },
  [EndReason.OTHER_SESSION]: {
    title: 'You have logged in elsewhere',
    description:
      'You are logged in a different browser or window. Please ensure you only have one instance of Sparx Assessments open.',
    reloadable: true,
    showLogout: true,
  },
};

const SittingEndedView = ({ reason }: { reason: EndReason }) => {
  // Logout the user when this component is mounted
  const settings = sittingEndedMessage[reason];
  useEffect(() => {
    if (!settings.reloadable) {
      logout(true);
    }
  }, [settings]);

  return (
    <Modal isOpen={true} overlayDismiss={false}>
      <Modal.Content>
        <Modal.Title>{settings.title}</Modal.Title>
        <Modal.Body>{settings.description}</Modal.Body>
        <Modal.Footer>
          {settings.showLogout && (
            <Button variant="outline" onClick={() => logout()}>
              Sign out
            </Button>
          )}
          {settings.reloadable ? (
            <Button autoFocus onClick={() => window.location.reload()}>
              Reload
            </Button>
          ) : (
            <Button onClick={redirectToRoot}>Return to homepage</Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

interface SittingContext {
  sitting: Sitting;
  now?: Timestamp;
  onEnd: (end: EndReason) => void;
}

const SittingContext = createContext<SittingContext>({} as SittingContext);

export const useSittingContext = () => useContext(SittingContext);

interface SittingViewProps {
  sittingName: string;
  sessionID: string;
  onEnd: (reason: EndReason) => void;
}

const SittingView = ({ sittingName, sessionID, onEnd }: SittingViewProps) => {
  const { data: sitting, isLoading } = useWatchSitting(sittingName, sessionID, {
    onMessage: ({ voidedPackageNames, now }) => {
      if (now) {
        updateOffset(now);
      }
      if (voidedPackageNames.length > 0) {
        updateVoidedPackages(sittingName, voidedPackageNames);
      }
    },
    onError: err => {
      if (err instanceof RpcError) {
        if (err.message === 'user is not a participant') {
          onEnd(EndReason.REMOVED);
        } else if (err.message === 'invalid session') {
          onEnd(EndReason.OTHER_SESSION);
        }
      }
    },
  });
  useSittingParticipantStatePusher(sittingName);

  // If the sitting is ended, then end the view
  useEffect(() => {
    if (sitting?.sitting?.state?.state === SittingDeliveryState.COMPLETED) {
      onEnd(EndReason.ENDED);
    }
  }, [sitting?.sitting?.state?.state, onEnd]);

  if (isLoading) {
    return <LargeLoadingPage />;
  }
  if (!sitting?.sitting) {
    return 'Not found'; // TODO
  }

  let content;
  switch (sitting?.sitting?.state?.state) {
    case SittingDeliveryState.SCHEDULED:
      content = (
        <SittingPage>
          <div className={styles.JoinWarning}>
            <FontAwesomeIcon icon={faHourglass} fade={true} size="3x" />
            <h2>Assessment joined</h2>
            <h3>Waiting for teacher to click the start button</h3>
            <Logo className={styles.FloatingLogo} />
          </div>
        </SittingPage>
      );
      break;
    case SittingDeliveryState.IN_PROGRESS:
      if (sitting?.sitting?.state?.pauseState === PauseState.PAUSED) {
        content = (
          <SittingPage>
            <Stack direction="column" align="center" spacing={2}>
              <div className={styles.PauseIcon}>
                <FontAwesomeIcon icon={faCirclePause} />
              </div>
              <h2>Your teacher has paused the assessment</h2>
              <Logo className={styles.FloatingLogo} />
            </Stack>
          </SittingPage>
        );
      } else {
        content = <StudentPackage sittingName={sittingName} onEnd={onEnd} />;
      }
  }

  return (
    <AnimatedFilledPage>
      <SittingContext.Provider value={{ sitting: sitting.sitting, now: sitting.now, onEnd }}>
        {content}
      </SittingContext.Provider>
    </AnimatedFilledPage>
  );
};

interface SittingPageProps {
  header?: ReactNode;
  className?: string;
}

export const SittingPage = ({
  children,
  header,
  className,
}: PropsWithChildren<SittingPageProps>) => (
  <>
    <SittingHeader>{header}</SittingHeader>
    <section className={styles.Container}>
      <div className={classNames(styles.Content, className)} id="sitting-content">
        {children}
      </div>
    </section>
  </>
);

const SittingHeader = ({ children }: PropsWithChildren) => {
  const { sitting } = useSittingContext();
  const { data: session } = useSession();

  // Toggleable full screen
  const fullScreenEnabled = Boolean(document.documentElement.requestFullscreen);
  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen({ navigationUI: 'hide' });
    }
  };

  // Track whether or not we are full screen
  const [isFullScreen, setIsFullScreen] = useState(Boolean(document.fullscreenElement));
  useEffect(() => {
    const handler = () => setIsFullScreen(Boolean(document.fullscreenElement));
    document.addEventListener('fullscreenchange', handler);
    return () => document.removeEventListener('fullscreenchange', handler);
  }, []);

  return (
    <header className={styles.Header}>
      <h2>{sitting?.studentTitle || sitting?.title}</h2>
      <div className={styles.HeaderContent}>{children}</div>
      <DropdownMenu.Root>
        <Button
          leftIcon={<FontAwesomeIcon icon={faUser} />}
          variant="ghost"
          as={DropdownMenu.Trigger}
          style={{ marginLeft: 20 }}
        >
          {session?.givenName} {session?.familyName}
        </Button>
        <DropdownMenuContent rounded="all">
          {fullScreenEnabled && (
            <>
              <DropdownMenuItem
                icon={isFullScreen ? faCompress : faExpand}
                onClick={toggleFullscreen}
              >
                {isFullScreen ? 'Exit full screen' : 'Full screen'}
              </DropdownMenuItem>
              <DropdownSeparator />
            </>
          )}
          <DropdownMenuItem icon={faSignOut} onClick={() => logout()}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </header>
  );
};

const StudentPackage = ({
  sittingName,
  onEnd,
}: {
  sittingName: string;
  onEnd: (reason: EndReason) => void;
}) => {
  const { data: packages } = useStudentSittingPackages(sittingName, {
    suspense: true,
  });

  // TODO: better selection
  const firstPackage = packages?.packages?.[0];
  const { data: pkg } = usePackage(firstPackage?.name || '', {
    suspense: true,
    enabled: !!firstPackage,
  });

  const [lastPackageName, setLastPackageName] = useState('');
  const [testWasRestarted, setTestWasRestarted] = useState(false);
  useEffect(() => {
    if (firstPackage?.name) {
      if (lastPackageName && lastPackageName !== firstPackage?.name) {
        setTestWasRestarted(true);
      }
      setLastPackageName(firstPackage?.name);
    }
  }, [firstPackage?.name, setLastPackageName, setTestWasRestarted, lastPackageName]);

  const isComplete = Boolean(pkg?.package?.state?.completionTimestamp);
  useEffect(() => {
    if (isComplete) {
      onEnd(EndReason.COMPLETED);
    }
  }, [isComplete, onEnd]);

  const isAdaptive = pkg?.package?.annotations?.['assessment/delivery'] === 'adaptive';
  if (!pkg?.package) {
    return 'No package';
  }

  return (
    <>
      <Modal isOpen={testWasRestarted} onClose={() => setTestWasRestarted(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Title>Assessment restarted</Modal.Title>
          <Modal.Body>Your teacher has restarted your assessment.</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setTestWasRestarted(false)}>Okay</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {isAdaptive ? (
        <AdaptiveAssessmentPackage pkg={pkg.package} />
      ) : (
        <QuizPackage pkg={pkg.package} />
      )}
    </>
  );
};
