import { AssessmentContext } from 'app/AssessmentContext';
import { GlobalErrorBoundary, GlobalRouteError } from 'app/GlobalErrorBoundary';
import { IntercomContext } from 'app/Intercom';
import { SuspenseRoute } from 'app/SuspenseRoute';
import { AnalyticsProvider } from 'components/analyticsprovider/AnalyticsProvider';
import { EnsureSession } from 'components/ensuresession/EnsureSession';
import { NavigationControlProvider } from 'components/header/BackLink';
import { TeacherHeader } from 'components/header/TeacherHeader';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { JoinView } from 'views/landing/joinview/JoinView';
import { LoginRedirectView } from 'views/landing/loginredirectview/LoginRedirectView';
import { SelectView } from 'views/landing/selectview/SelectView';
import { StudentHomeView } from 'views/student/studenthomeview/StudentHomeView';
import { AssessmentHomeView } from 'views/teacher/assessmentsview/AssessmentHomeView';
import { AssessmentMatchView } from 'views/teacher/assessmentsview/AssessmentMatchView';
import { AssessmentsView } from 'views/teacher/assessmentsview/AssessmentsView';
import { OnlineDataEntryView } from 'views/teacher/assessmentsview/dataentryview/OnlineDataEntryView';
import { PaperDataEntryView } from 'views/teacher/assessmentsview/dataentryview/PaperDataEntryView';
import { CreateSittingView } from 'views/teacher/createsittingview/CreateSittingView';
import { HomeView } from 'views/teacher/homeview/HomeView';
import { Sidebar } from 'views/teacher/sidebar/Sidebar';
import { SittingsListView } from 'views/teacher/sittingslistview/SittingsListView';
import { AnswerHistoryView } from 'views/teacher/sittingview/answerhistory/AnswerHistoryView';
import { SittingView } from 'views/teacher/sittingview/SittingView';
import { TokenAuthView } from 'views/teacher/tokenauthview/TokenAuthView';

// const AnimatedOutlet = () => {
//   const location = useLocation();
//   const element = useOutlet();
//   return (
//     <AnimatePresence mode="wait" initial={true}>
//       {element && cloneElement(element, { key: location.pathname })}
//     </AnimatePresence>
//   );
// };

export const router = createBrowserRouter([
  {
    // element: <AnimatedOutlet />,
    errorElement: <GlobalRouteError />,
    Component: GlobalErrorBoundary,
    children: [
      {
        path: '/',
        element: (
          <AnalyticsProvider>
            <EnsureSession requireUnauthenticated={true}>
              <SelectView />
            </EnsureSession>
          </AnalyticsProvider>
        ),
      },
      {
        path: '/login',
        element: <LoginRedirectView />,
      },
      {
        path: '/join',
        element: (
          <AnalyticsProvider>
            <EnsureSession requireUnauthenticated={true}>
              <JoinView />
            </EnsureSession>
          </AnalyticsProvider>
        ),
      },
      {
        path: '/guest',
        element: <TokenAuthView />,
      },
      {
        path: '/teacher',
        element: (
          <AnalyticsProvider>
            <EnsureSession userType="staff">
              <NavigationControlProvider>
                <AssessmentContext>
                  <IntercomContext>
                    <TeacherHeader />
                    <Sidebar>
                      <SuspenseRoute>
                        <Outlet />
                      </SuspenseRoute>
                    </Sidebar>
                  </IntercomContext>
                </AssessmentContext>
              </NavigationControlProvider>
            </EnsureSession>
          </AnalyticsProvider>
        ),
        children: [
          {
            path: '',
            element: <HomeView />,
          },
          {
            path: 'sittings',
            element: <SittingsListView />,
          },
          {
            path: 'sittings/create',
            element: <CreateSittingView />,
          },
          {
            path: 'sittings/:sittingId',
            element: <SittingView />,
          },
          {
            path: 'sittings/:sittingId/answers/:userId',
            element: <AnswerHistoryView />,
          },
          {
            path: 'assessments',
            element: <AssessmentsView />,
          },
          {
            path: 'assessments/:assessmentId',
            children: [
              {
                path: '',
                element: <AssessmentHomeView />,
              },
              {
                path: 'dataentry',
                element: <PaperDataEntryView />,
              },
              {
                path: 'submissions',
                element: <OnlineDataEntryView />,
              },
              {
                path: 'match',
                element: <AssessmentMatchView />,
              },
            ],
          },

          // Staff manager routes
          {
            path: 'staff',
            element: <Outlet />,
            children: [
              {
                path: '',
                lazy: () => import('views/staff/StaffView'),
              },
              {
                path: ':userId',
                lazy: () => import('views/staff/EditStaffView'),
              },
            ],
          },
        ],
      },
      {
        path: '/student',
        element: (
          <AnalyticsProvider>
            <EnsureSession userType="student">
              <Outlet />
            </EnsureSession>
          </AnalyticsProvider>
        ),
        children: [
          {
            path: '',
            element: <StudentHomeView />,
          },
        ],
      },
    ],
  },
]);
