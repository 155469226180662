// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/hints/v1/hints.proto" (package "sparx.hints.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * Hint represents an instance of a hint that can be given to a student
 *
 * @generated from protobuf message sparx.hints.v1.Hint
 */
export interface Hint {
  /**
   * @generated from protobuf field: sparx.hints.v1.HintType hintType = 1;
   */
  hintType: HintType;
  /**
   * refs to the inputs that this hint applies to
   *
   * @generated from protobuf field: repeated string inputRefs = 2;
   */
  inputRefs: string[];
  /**
   * The targeted support data for this hint. Optional
   *
   * @generated from protobuf field: sparx.hints.v1.TargetedSupportHint targeted_support = 3;
   */
  targetedSupport?: TargetedSupportHint;
}
/**
 * TargetedSupportHint is additional data needed for a Hint of type HINT_TARGETED_SUPPORT
 *
 * @generated from protobuf message sparx.hints.v1.TargetedSupportHint
 */
export interface TargetedSupportHint {
  /**
   * The text to show to the student
   *
   * @generated from protobuf field: string support_text = 1;
   */
  supportText: string;
  /**
   * Whether the student needs to return to the question to action the support
   *
   * @generated from protobuf field: bool return_to_question = 2;
   */
  returnToQuestion: boolean;
  /**
   * The additional support material to show to the user. Optional
   *
   * @generated from protobuf field: sparx.hints.v1.AdditionalSupport additional_support = 3;
   */
  additionalSupport?: AdditionalSupport;
}
/**
 * TargetedSupport represents a type of targeted support that can be given for a particular skill
 *
 * @generated from protobuf message sparx.hints.v1.TargetedSupport
 */
export interface TargetedSupport {
  /**
   * The support id for this skill.
   *
   * @generated from protobuf field: string support_id = 1;
   */
  supportId: string;
  /**
   * The text displayed to the student. If multiple values are given they will be cycled
   * through each time the hint is given for an attempt on the same question. Any further
   * attempts will use the last value.
   *
   * @generated from protobuf field: repeated string support_text = 2;
   */
  supportText: string[];
  /**
   * Whether to return to the question after the support is shown.
   *
   * @generated from protobuf field: bool return_to_question = 3;
   */
  returnToQuestion: boolean;
  /**
   * The additional support material to show to the user. Optional
   *
   * @generated from protobuf field: sparx.hints.v1.AdditionalSupport additional_support = 4;
   */
  additionalSupport?: AdditionalSupport;
}
/**
 * AdditionalSupport represents additional support material to show to the user as part of targeted support
 *
 * @generated from protobuf message sparx.hints.v1.AdditionalSupport
 */
export interface AdditionalSupport {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: string video_id = 2;
   */
  videoId: string;
  /**
   * @generated from protobuf field: string image_id = 3;
   */
  imageId: string;
}
/**
 * TargetedSupportConfig represents the per question configuration required for targeted support
 *
 * @generated from protobuf message sparx.hints.v1.TargetedSupportConfig
 */
export interface TargetedSupportConfig {
  /**
   * The support id for this question, referenced from the skill.
   *
   * @generated from protobuf field: string skill_support_id = 1;
   */
  skillSupportId: string;
  /**
   * The targeted answer for this support
   *
   * @generated from protobuf field: string target_answer = 2;
   */
  targetAnswer: string;
  /**
   * Optional support text, this will override the support text from the skill.
   *
   * @generated from protobuf field: string support_text = 3;
   */
  supportText: string;
}
/**
 * @generated from protobuf enum sparx.hints.v1.HintType
 */
export enum HintType {
  /**
   * @generated from protobuf enum value: HINT_UNSPECIFIED = 0;
   */
  HINT_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: HINT_SIMPLIFY_FRACTION = 1;
   */
  HINT_SIMPLIFY_FRACTION = 1,
  /**
   * @generated from protobuf enum value: HINT_BEARINGS = 2;
   */
  HINT_BEARINGS = 2,
  /**
   * @generated from protobuf enum value: HINT_MONEY = 3;
   */
  HINT_MONEY = 3,
  /**
   * @generated from protobuf enum value: HINT_ROUNDING = 4;
   */
  HINT_ROUNDING = 4,
  /**
   * @generated from protobuf enum value: HINT_TARGETED_SUPPORT = 5;
   */
  HINT_TARGETED_SUPPORT = 5,
}
// @generated message type with reflection information, may provide speed optimized methods
class Hint$Type extends MessageType<Hint> {
  constructor() {
    super('sparx.hints.v1.Hint', [
      {
        no: 1,
        name: 'hintType',
        kind: 'enum',
        T: () => ['sparx.hints.v1.HintType', HintType],
      },
      {
        no: 2,
        name: 'inputRefs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'targeted_support',
        kind: 'message',
        T: () => TargetedSupportHint,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.Hint
 */
export const Hint = new Hint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TargetedSupportHint$Type extends MessageType<TargetedSupportHint> {
  constructor() {
    super('sparx.hints.v1.TargetedSupportHint', [
      {
        no: 1,
        name: 'support_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'return_to_question',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'additional_support',
        kind: 'message',
        T: () => AdditionalSupport,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.TargetedSupportHint
 */
export const TargetedSupportHint = new TargetedSupportHint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TargetedSupport$Type extends MessageType<TargetedSupport> {
  constructor() {
    super('sparx.hints.v1.TargetedSupport', [
      { no: 1, name: 'support_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'support_text',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'return_to_question',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'additional_support',
        kind: 'message',
        T: () => AdditionalSupport,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.TargetedSupport
 */
export const TargetedSupport = new TargetedSupport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdditionalSupport$Type extends MessageType<AdditionalSupport> {
  constructor() {
    super('sparx.hints.v1.AdditionalSupport', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'image_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.AdditionalSupport
 */
export const AdditionalSupport = new AdditionalSupport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TargetedSupportConfig$Type extends MessageType<TargetedSupportConfig> {
  constructor() {
    super('sparx.hints.v1.TargetedSupportConfig', [
      {
        no: 1,
        name: 'skill_support_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'target_answer',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'support_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.TargetedSupportConfig
 */
export const TargetedSupportConfig = new TargetedSupportConfig$Type();
