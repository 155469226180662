import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faRedo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  JoinRequest,
  Student,
  UpdateSittingRequest,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Date } from '@sparx/api/google/type/date';
import inputStyles from '@sparx/sparx-design/components/input/Input.module.css';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useSittingParticipants, useUpdateSitting } from 'api/sittings';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';

import styles from './JoinRequests.module.css';

export const JoinRequests = ({
  joinRequests,
  sittingName,
}: {
  joinRequests: JoinRequest[];
  sittingName: string;
}) => {
  const [open, setOpen] = useState(false);
  const [respondingRequestId, setRespondingRequest] = useState<string | null>(null);
  const respondingRequest = joinRequests.find(r => r.requestId === respondingRequestId);
  useEffect(() => {
    if (open && !respondingRequest) {
      setOpen(false);
    }
  }, [open, setOpen, respondingRequest]);

  return (
    <>
      <RespondRequestModal
        request={respondingRequest}
        sittingName={sittingName}
        open={open}
        onClose={() => setOpen(false)}
      />
      <AnimatePresence>
        {joinRequests.map(request => (
          <motion.tr
            key={request.requestId}
            initial={{ transform: 'translateX(-100px)', opacity: 0 }}
            animate={{ transform: 'translateX(0px)', opacity: 1 }}
            exit={{ transform: 'translateX(100px)', opacity: 0 }}
          >
            <td
              colSpan={5}
              className={styles.RequestRow}
              onClick={() => {
                setOpen(true);
                setRespondingRequest(request.requestId);
              }}
            >
              <div className={styles.RequestRowDetails}>
                Join request:
                <strong>
                  {request.givenName} {request.familyName}
                </strong>
                <span>
                  ({request?.dateOfBirth?.day}/{request?.dateOfBirth?.month}/
                  {request?.dateOfBirth?.year})
                </span>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </td>
          </motion.tr>
        ))}
      </AnimatePresence>
    </>
  );
};

const RespondRequestModal = ({
  request,
  sittingName,
  open,
  onClose,
}: {
  request: JoinRequest | undefined;
  sittingName: string;
  open: boolean;
  onClose: () => void;
}) => {
  const {
    mutateAsync: updateSitting,
    isLoading,
    variables,
  } = useUpdateSitting(sittingName, {
    onSuccess: onClose,
  });

  const { data: guests = [] } = useSittingParticipants(sittingName, {
    select: data => data.students.filter(s => s.subject.startsWith('assessmentsguest/')),
  });

  const matches: Record<string, Student[]> = {
    'Exact match': [],
    'Same name': guests.filter(
      g =>
        g.givenName.toLowerCase() === request?.givenName.toLowerCase() &&
        g.familyName.toLowerCase() === request?.familyName.toLowerCase(),
    ),
    'Same date of birth': guests.filter(g => Date.equals(g.dateOfBirth, request?.dateOfBirth)),
  };

  for (const match of [...matches['Same name']]) {
    if (matches['Same date of birth'].includes(match)) {
      matches['Exact match'].push(match);
      matches['Same name'].splice(matches['Same name'].indexOf(match), 1);
      matches['Same date of birth'].splice(matches['Same date of birth'].indexOf(match), 1);
    }
  }

  const buttonPropsForArgs = (accept: boolean, subject?: string) => {
    const r: UpdateSittingRequest['action'] = {
      oneofKind: 'respondJoin',
      respondJoin: { requestId: request?.requestId || '', accept: accept, subject: subject || '' },
    };
    return {
      onClick: () => updateSitting(r),
      isLoading: isLoading && variables === r,
      isDisabled: isLoading && variables !== r,
    };
  };

  const [chooseStudent, setChooseStudent] = useState(false);
  const [selectedStudentSubject, setSelectedStudent] = useState<undefined | string>();
  const selectedStudent = guests?.find(g => g.subject === selectedStudentSubject);
  const onChooseStudent = () => {
    setSelectedStudent(undefined);
    setChooseStudent(true);
  };
  useEffect(() => {
    if (open) {
      setChooseStudent(false);
      setSelectedStudent(undefined);
    }
  }, [open]);

  let options = (
    <Stack spacing={2} direction="column" justify="stretch">
      {Object.entries(matches)
        .filter(([_, matches]) => matches.length > 0)
        .map(([label, matches]) =>
          matches.map(match => (
            <div key={label + match.subject}>
              <Button
                {...buttonPropsForArgs(true, match?.subject)}
                className={styles.Button}
                leftIcon={<FontAwesomeIcon icon={faRedo} fixedWidth={true} />}
              >
                <strong>Rejoin as: ({label})</strong>
                <br />
                {match?.givenName} {match?.familyName} ({match?.dateOfBirth?.day}/
                {match?.dateOfBirth?.month}/{match?.dateOfBirth?.year})
              </Button>
            </div>
          )),
        )}
      <Button
        {...buttonPropsForArgs(true)}
        isLoading={isLoading}
        className={styles.Button}
        leftIcon={<FontAwesomeIcon icon={faCheck} fixedWidth={true} />}
      >
        Join as new student
      </Button>
      <Button
        onClick={onChooseStudent}
        isLoading={isLoading}
        className={styles.Button}
        variant="outline"
        leftIcon={<FontAwesomeIcon icon={faRedo} fixedWidth={true} />}
      >
        Select a student to join as
      </Button>
      <Button
        {...buttonPropsForArgs(false)}
        className={styles.Button}
        colour="red"
        variant="outline"
        leftIcon={<FontAwesomeIcon icon={faTimes} fixedWidth={true} />}
      >
        Reject join request
      </Button>
    </Stack>
  );

  if (chooseStudent) {
    options = (
      <Stack spacing={2} direction="column" justify="stretch">
        <Button
          onClick={() => setChooseStudent(false)}
          className={styles.Button}
          variant="outline"
          isDisabled={isLoading}
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} fixedWidth={true} />}
        >
          Back
        </Button>
        <select
          className={classNames(inputStyles.Input)}
          value={selectedStudentSubject}
          onChange={e => setSelectedStudent(e.target.value)}
        >
          <option disabled selected value={undefined}>
            Select a student...
          </option>
          {guests?.map(g => (
            <option key={g.subject} value={g.subject}>
              {g.givenName} {g.familyName} ({g.dateOfBirth?.day}/{g.dateOfBirth?.month}/
              {g.dateOfBirth?.year})
            </option>
          ))}
        </select>
        <Button
          {...buttonPropsForArgs(true, selectedStudentSubject)}
          isDisabled={!selectedStudent}
          className={styles.Button}
          leftIcon={<FontAwesomeIcon icon={faRedo} fixedWidth={true} />}
        >
          Join as
          {selectedStudent ? ` ${selectedStudent.givenName} ${selectedStudent.familyName}` : '...'}
        </Button>
      </Stack>
    );
  }

  return (
    <Modal isOpen={open} onClose={onClose}>
      <Modal.Content width="md">
        <Modal.CloseButton />
        <Modal.Title>Respond to join request</Modal.Title>
        <Modal.Body>
          <div className={styles.Request}>
            Join request from:
            <h3>
              {request?.givenName} {request?.familyName} ({request?.dateOfBirth?.day}/
              {request?.dateOfBirth?.month}/{request?.dateOfBirth?.year})
            </h3>
          </div>
          {options}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
